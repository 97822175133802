<!-- 订单评价--标题 -->
<template>
  <div class="remarkinfotitle">
    <h6>{{titles}}</h6>
    <div class="box">
      <p>订单号：<span>{{popData&&popData.OrderName?popData.OrderName:''}}</span></p>
      <p>{{popData&&popData.OrderDate?popData.OrderDate:''  | times}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'remarkinfotitle',
  props: {
    titles: String,
    popData:Object
  },
  methods:{
  },
  mounted(){
  },
  filters:{
    times(v){
      if(v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }else{
        return ''
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.remarkinfotitle {
  text-align: center;
  h6 {
    font-size: 18px;padding:20px 0 10px 0;
  }
  .box {
    display: flex;width: 600px;margin:0 auto;justify-content: center;
    p {
      font-size: 14px;color:#a4a6a2;
      span {
        font-size:14px;color:#333;
      }
      &:last-child {
        margin-right:40px;margin-left:40px;
      }
    }
  }
}
</style>
