<!-- 评论产品信息 -->
<template>
  <div class="remarkproduct">
    <div class="lt">
      <div class="img">
        <img :src="this._src + (popData&&popData.ImagePath?popData.ImagePath:'')">
      </div>
      <div class="h6">
        <h6>{{popData&&popData.ProductTypeName?popData.ProductTypeName:''}}</h6>
      </div>
      <div class="price">
        &yen;{{popData&&popData.Price?popData.Price:''}}
      </div>
    </div>
    <div class="rt">
      <div class="group">
        <div class="lts">
          商品评分
          <div class="error" v-show='pointerr'>
            <p>请填写评分</p>
          </div>
        </div>
        <!-- v-if='popData&&popData.ProductEvaluateList.length>0?false:true' -->
        <div class="rts" >
          <div class="point">
            <div class="rtss" :class="['rtss1',' rtss2',' rtss3','rtss4','rtss5'][idx==0?idxs:this.points[0]-1]">
              <svg  @mouseenter='mouseenter({idx:0,idxs:0})' @mouseleave='mouseleave({idx:0,idxs:0})' @click='pt(0,0)'><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg  @mouseenter='mouseenter({idx:0,idxs:1})' @mouseleave='mouseleave({idx:0,idxs:1})' @click='pt(0,1)'><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg  @mouseenter='mouseenter({idx:0,idxs:2})' @mouseleave='mouseleave({idx:0,idxs:2})' @click='pt(0,2)'><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg  @mouseenter='mouseenter({idx:0,idxs:3})' @mouseleave='mouseleave({idx:0,idxs:3})' @click='pt(0,3)'><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg  @mouseenter='mouseenter({idx:0,idxs:4})' @mouseleave='mouseleave({idx:0,idxs:4})' @click='pt(0,4)'><use xlink:href="#iconwujiaoxing"></use></svg>
            </div>
            <div class="f" :class='{fr:idx==0}'>
              {{idx==0?idxs+1:this.points[0]}}分
            </div>
            <div class="svgTop" :class="['svgTop1',' svgTop2',' svgTop3','svgTop4','svgTop5'][idx==0?idxs:null]">
              <svg><use xlink:href="#iconbumanyi"></use></svg>
              <svg><use xlink:href="#iconbumanyi"></use></svg>
              <svg><use xlink:href="#iconyiban"></use></svg>
              <svg><use xlink:href="#iconziyuan14"></use></svg>
              <svg><use xlink:href="#iconziyuan14"></use></svg>
            </div>
          </div>
        </div>
      </div>
      <template v-if='popData&&popData.ProductEvaluateList.length>0?true:false' v-for='item,index in popData&&popData.ProductEvaluateList.length>0?popData.ProductEvaluateList:""' :keys='index'>
        <div class="group group-detail">
          <div class="rts">
            <p class="infos">{{item.Content}}</p>
          </div>
        </div>
        <div class="group group-detail">
          <div class="rts">
            <div class="lists">
              <div class="img" v-for='items,indexs in item.imglist' :keys='indexs'>
                <img :src="items.ImageUrl | imgsrc(that)" @click='previewDetailimg(items.ImageUrl)'>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="group sd">
        <div class="lts">
          评价晒单
          <div class="error" v-show='txterr'>
            <p>请填写评价心得</p>
          </div>
        </div>
        <div class="rts">
          <div class="textarea">
            <textarea maxlength="500"  v-model='rmkObj.rmk' placeholder='分享体验心得，给万千想买的人一个参考~'></textarea>
            <span class="txtnum">{{rmkObj.rmk?rmkObj.rmk.length:0}}/500</span>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="lts">
        </div>
        <div class="rts">
          <div class="uploadimg">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :multiple='true'
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :before-upload='upfn'
              :http-request='selfupload'
              accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF,"
              >
              <span class="iconss"><svg><use xlink:href="#iconxiangji"></use></svg></span>
              <p>电脑传图</p>
            </el-upload>
            <div class="imgnum">共<span>{{uploadImg}}</span>张，还能上传<span>{{maxUploadImg-uploadImg}}</span>张</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'remarkproduct',
  props: {
    popData:Object,
    popExp:Object,
  },
  data(){
    return {
      idx:null,//星星索引
      idxs:null,//星星索引
      points:[0,0,0,0],//评分值
      dialogImageUrl: '',
      dialogVisible: false,
      uploadImg:0,//当前上传图片数量
      maxUploadImg:9,//上传图片上限
      rmkObj:{
        uploaddata:[],
        point:0,
        rmk:''
      },//评论数据对象
      that:this,
      pointerr:false,//评分验证
      txterr:false,//评论验证
    }
  },
  methods:{
    mouseenter(obj){
      // 滑入
      if(this.popData&&this.popData.ProductEvaluateList.length<1){
        this.idx = obj.idx
        this.idxs = obj.idxs
      }
    },
    mouseleave(obj){
      // 离开
      this.idx = null
    },
    pt(idx,v){
      // 点击评分
      if(this.popData&&this.popData.ProductEvaluateList.length<1){
        this.points[idx] = v+1
        this.rmkObj.point = this.points[0]
        this.pointerr = false
        this.$emit('expupload',this.rmkObj)
      }
    },
    handleRemove(file, fileList) {
      // 删除上传图片
      this.uploadImg = fileList.length
      this.rmkObj.uploaddata = [];
      for(let i in fileList){
        this.rmkObj.uploaddata.push(fileList[i].raw)
      }
      this.$emit('expupload',this.rmkObj)
    },
    handlePictureCardPreview(file) {
      // 预览上传图片
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    previewDetailimg(v){
      // 预览详情图片
      this.dialogImageUrl = this._src + v;
      this.dialogVisible = true;
    },
    upfn(file){
      // 上传前计数
      this.uploadImg++
      if(this.uploadImg>this.maxUploadImg){
        this.uploadImg=this.maxUploadImg
        this.$MessageBox.alert('最多上传9张图片',{type:'error'});
        return false
      }
      // return;
    },
    selfupload(file){
      // 自定义上传
      this.rmkObj.uploaddata.push(file.file)
      this.$emit('expupload',this.rmkObj)
    },
  },
  mounted(){
  },
  watch:{
    'rmkObj.rmk'(n,o){
      this.txterr = false
      this.$emit('expupload',this.rmkObj)
    },
    popData(n,o){
      if(n&&n.ProductEvaluateList.length>0){
        // 分数赋值
        this.points[0] = n.ProductEvaluateList[0].Score/2
      }
    },
    'popExp.point'(n,o){
      // 验证是否评分
      this.pointerr = true
    },
    'popExp.txt'(n,o){
      // 验证是否评论
      this.txterr = true
    }
  },
  filters:{
    imgsrc(v,that){
      return that._src+v
    }
  }
}
</script>
<style  lang="scss" scoped>
.remarkproduct {
  background: #fff;display: flex;justify-content: center;
  .lt {
    width: 337px;border-right: 1px solid #f2f2f2;
    .img {
      display: flex;justify-content: center;height: 170px;align-items: flex-end;margin-bottom:10px;
      img {
        display: block;width: 100px;height: 100px;
      }
    }
    .h6 {
      height: 38px;display: flex;justify-content: center;align-items: center;width: 210px;margin:0 auto;margin-bottom:30px;
      h6 {
        font-weight: normal;font-size: 14px;overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .price {
      text-align: center;color:#e51c23;font-size: 14px;font-weight: bold;
    }
  }
  .rt {
    width: 862px;padding-top:70px;
    .group {
      margin-bottom:35px;zoom:1;
      .lts {
        width: 100px;float:left;text-align: right;color:#666;font-size: 14px;position: relative;
        .error {
          position: absolute;font-size: 12px;color:#e51c23;top:22px;left:44px;width: 100px;text-align: left;
        }
      }
      .rts {
        width: 640px;float:right;margin-right: 40px;
        .point {
          display: flex;justify-content: flex-start;position: relative;
          .rtss {
            margin-right: 10px;
            svg {
              fill: #e2e2e2;width: 20px;height: 20px;cursor: pointer;
            }
          }
          .rtss1 {
            svg {
              &:nth-child(1) {
                fill: #e93536;
              }
            }
          }
          .rtss2 {
            svg {
              &:nth-child(-n+2) {
                fill: #e93536;
              }
            }
          }
          .rtss3 {
            svg {
              &:nth-child(-n+3) {
                fill: #e93536;
              }
            }
          }
          .rtss4 {
            svg {
              &:nth-child(-n+4) {
                fill: #e93536;
              }
            }
          }
          .rtss5 {
            svg {
              &:nth-child(-n+5) {
                fill: #e93536;
              }
            }
          }
          .f {
            margin-right: 20px;font-size: 14px;color:#666;
          }
          .fr {
            color:#e93536;
          }
          .svgTop {
            position: absolute;top:-20px;left:0px;
            svg {
              width: 18px;height:18px;margin-right: 2px;fill: #e93536;visibility: hidden;
            }
          }
          .svgTop1 {
            svg {
              &:nth-child(1) {
                visibility: visible;
              }
            }
          }
          .svgTop2 {
            svg {
              &:nth-child(2) {
                visibility: visible;
              }
            }
          }
          .svgTop3 {
            svg {
              &:nth-child(3) {
                visibility: visible;
              }
            }
          }
          .svgTop4 {
            svg {
              &:nth-child(4) {
                visibility: visible;
              }
            }
          }
          .svgTop5 {
            svg {
              &:nth-child(5) {
                visibility: visible;
              }
            }
          }
        }
        .textarea {
          position: relative;
          textarea {
            width: 618px;height: 100px;border:1px solid #e0e0e0;padding:10px;margin-top:-10px;font-size: 12px;
            &::placeholder {
              font-size: 12px;color:#757575;
            }
          }
          .txtnum {
            position: absolute;bottom:7px;right:10px;font-size: 12px;
          }
        }
        .uploadimg {
          /deep/.el-upload {
            width: 50px;height: 50px;line-height: 8px;
            p {
              font-size: 12px;transform: scale(0.8);margin-top:3px;
            }
          }
          /deep/.el-upload-list__item {width: 50px;height: 50px;line-height: 50px;margin-bottom:0px;}
          /deep/.iconss {
            vertical-align: -4px;
            svg {
              width: 30px;height: 30px;fill: #ccc;
            }
          }
          .imgnum {
            font-size: 12px;color:#999999;margin-top:5px;
            span {
              color:#e4393c;font-size: 12px;margin:0 2px;
            }
          }
        }
        .lists {
          display: flex;justify-content: flex-start;
          .img {
            width: 50px;height: 50px;border:1px solid #e2e2e2;display: flex;justify-content: center;align-items: center;margin-right: 10px;
            img {
              display: block;max-width: 45px;max-height: 45px;
            }
          }
        }
      }
      &:after {
        display: block;clear: both;content: "";
      }
    }
    .sd {
      margin-bottom:0px;margin-top:40px;
    }
    .group-detail {
      margin-bottom:10px;
    }
  }
}
</style>
