<!-- 评价评分 -->
<template>
  <div class="remarkpoint">
    <div class="point">
      <div class="lt">
        商品符合度
      </div>
      <div class="rt" :class="['rt1',' rt2',' rt3','rt4','rt5'][idx==0?idxs:this.points[0]-1]">
        <svg  @mouseenter='mouseenter({idx:0,idxs:0})' @mouseleave='mouseleave({idx:0,idxs:0})' @click='pt(0,0)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:0,idxs:1})' @mouseleave='mouseleave({idx:0,idxs:1})' @click='pt(0,1)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:0,idxs:2})' @mouseleave='mouseleave({idx:0,idxs:2})' @click='pt(0,2)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:0,idxs:3})' @mouseleave='mouseleave({idx:0,idxs:3})' @click='pt(0,3)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:0,idxs:4})' @mouseleave='mouseleave({idx:0,idxs:4})' @click='pt(0,4)'><use xlink:href="#iconwujiaoxing"></use></svg>
      </div>
      <div class="f" :class='{fr:idx==0}'>
        {{idx==0?idxs+1:this.points[0]}}分
      </div>
      <div class="svgTop" :class="['svgTop1',' svgTop2',' svgTop3','svgTop4','svgTop5'][idx==0?idxs:null]">
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconyiban"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
      </div>
    </div>
    <div class="point">
      <div class="lt">
        客服服务态度
      </div>
      <div class="rt" :class="['rt1',' rt2',' rt3','rt4','rt5'][idx==1?idxs:this.points[1]-1]">
        <svg  @mouseenter='mouseenter({idx:1,idxs:0})' @mouseleave='mouseleave({idx:0,idxs:0})' @click='pt(1,0)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:1,idxs:1})' @mouseleave='mouseleave({idx:0,idxs:1})' @click='pt(1,1)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:1,idxs:2})' @mouseleave='mouseleave({idx:0,idxs:2})' @click='pt(1,2)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:1,idxs:3})' @mouseleave='mouseleave({idx:0,idxs:3})' @click='pt(1,3)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:1,idxs:4})' @mouseleave='mouseleave({idx:0,idxs:4})' @click='pt(1,4)'><use xlink:href="#iconwujiaoxing"></use></svg>
      </div>
      <div  class="f" :class='{fr:idx==1}'>
        {{idx==1?idxs+1:this.points[1]}}分
      </div>
      <div class="svgTop" :class="['svgTop1',' svgTop2',' svgTop3','svgTop4','svgTop5'][idx==1?idxs:null]">
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconyiban"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
      </div>
    </div>
    <div class="point">
      <div class="lt">
        物流发货速度
      </div>
      <div class="rt" :class="['rt1',' rt2',' rt3','rt4','rt5'][idx==2?idxs:this.points[2]-1]">
        <svg  @mouseenter='mouseenter({idx:2,idxs:0})' @mouseleave='mouseleave({idx:0,idxs:0})' @click='pt(2,0)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:2,idxs:1})' @mouseleave='mouseleave({idx:0,idxs:1})' @click='pt(2,1)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:2,idxs:2})' @mouseleave='mouseleave({idx:0,idxs:2})' @click='pt(2,2)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:2,idxs:3})' @mouseleave='mouseleave({idx:0,idxs:3})' @click='pt(2,3)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:2,idxs:4})' @mouseleave='mouseleave({idx:0,idxs:4})' @click='pt(2,4)'><use xlink:href="#iconwujiaoxing"></use></svg>
      </div>
      <div  class="f" :class='{fr:idx==2}'>
        {{idx==2?idxs+1:this.points[2]}}分
      </div>
      <div class="svgTop" :class="['svgTop1',' svgTop2',' svgTop3','svgTop4','svgTop5'][idx==2?idxs:null]">
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconyiban"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
      </div>
    </div>
    <div class="point">
      <div class="lt">
        配送员服务
      </div>
      <div class="rt" :class="['rt1',' rt2',' rt3','rt4','rt5'][idx==3?idxs:this.points[3]-1]">
        <svg  @mouseenter='mouseenter({idx:3,idxs:0})' @mouseleave='mouseleave({idx:0,idxs:0})' @click='pt(3,0)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:3,idxs:1})' @mouseleave='mouseleave({idx:0,idxs:1})' @click='pt(3,1)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:3,idxs:2})' @mouseleave='mouseleave({idx:0,idxs:2})' @click='pt(3,2)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:3,idxs:3})' @mouseleave='mouseleave({idx:0,idxs:3})' @click='pt(3,3)'><use xlink:href="#iconwujiaoxing"></use></svg>
        <svg  @mouseenter='mouseenter({idx:3,idxs:4})' @mouseleave='mouseleave({idx:0,idxs:4})' @click='pt(3,4)'><use xlink:href="#iconwujiaoxing"></use></svg>
      </div>
      <div  class="f" :class='{fr:idx==3}'>
        {{idx==3?idxs+1:this.points[3]}}分
      </div>
      <div class="svgTop" :class="['svgTop1',' svgTop2',' svgTop3','svgTop4','svgTop5'][idx==3?idxs:null]">
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconbumanyi"></use></svg>
        <svg><use xlink:href="#iconyiban"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
        <svg><use xlink:href="#iconziyuan14"></use></svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'remarkpoint',
  props: {
    msg: String
  },
  data(){
    return {
      idx:null,
      idxs:null,
      points:[0,0,0,0]
    }
  },
  methods:{
    mouseenter(obj){
      // 滑入
      this.idx = obj.idx
      this.idxs = obj.idxs
    },
    mouseleave(obj){
      // 离开
      this.idx = null
    },
    pt(idx,v){
      // 点击评分
      this.points[idx] = v+1
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.remarkpoint {
  background: #fff;display: flex;justify-content: flex-start;padding-left:50px;padding-top:35px;
  .point {
    display: flex;justify-content: flex-start;position: relative;
    .lt {
      font-size: 14px;color:#666666;margin-right: 10px;
    }
    .rt {
      margin-right: 10px;
      svg {
        fill: #e2e2e2;width: 20px;height: 20px;cursor: pointer;
      }
    }
    .rt1 {
      svg {
        &:nth-child(1) {
          fill: #e93536;
        }
      }
    }
    .rt2 {
      svg {
        &:nth-child(-n+2) {
          fill: #e93536;
        }
      }
    }
    .rt3 {
      svg {
        &:nth-child(-n+3) {
          fill: #e93536;
        }
      }
    }
    .rt4 {
      svg {
        &:nth-child(-n+4) {
          fill: #e93536;
        }
      }
    }
    .rt5 {
      svg {
        &:nth-child(-n+5) {
          fill: #e93536;
        }
      }
    }
    .f {
      margin-right: 20px;font-size: 14px;color:#666;
    }
    .fr {
      color:#e93536;
    }
    .svgTop {
      position: absolute;top:-20px;left:80px;
      svg {
        width: 18px;height:18px;margin-right: 2px;fill: #e93536;visibility: hidden;
      }
    }
    .svgTop1 {
      svg {
        &:nth-child(1) {
          visibility: visible;
        }
      }
    }
    .svgTop2 {
      svg {
        &:nth-child(2) {
          visibility: visible;
        }
      }
    }
    .svgTop3 {
      svg {
        &:nth-child(3) {
          visibility: visible;
        }
      }
    }
    .svgTop4 {
      svg {
        &:nth-child(4) {
          visibility: visible;
        }
      }
    }
    .svgTop5 {
      svg {
        &:nth-child(5) {
          visibility: visible;
        }
      }
    }
    &:nth-child(2) {
      .svgTop {
        left: 94px;
      }
    }
    &:nth-child(3) {
      .svgTop {
        left: 94px;
      }
    }
  }
}
</style>
