<!-- 订单评价 -->
<template>
  <div class="remarkinfo">
    <remarkinfotitle class='remarkinfotitle' :popData='rmkData' titles='评价订单'></remarkinfotitle>
    <!-- <remarkpoint class='remarkpoint'></remarkpoint> -->
    <remarkproduct class='remarkproduct' :popData='rmkData' :popExp='expReq' @expupload='expupload'></remarkproduct>
    <div class="uploadBox">
      <button @click='rmkbtn' :disabled="disabledUpload">发表</button>
      <div class="box" :class="{boxslt:sltms}" @click='sltm' v-if='rmkData&&rmkData.ProductEvaluateList.length<1?true:false'>
        <span><svg><use xlink:href="#iconwancheng"></use></svg></span>
        匿名评价
      </div>
    </div>
  </div>
</template>
<script>
import remarkinfotitle from '@/components/remarkinfotitle/remarkinfotitle.vue';//顶部hr1
import remarkpoint from '@/components/remarkpoint/remarkpoint.vue';//物流评分
import remarkproduct from '@/components/remarkproduct/remarkproduct.vue';//产品评分
export default {
  name: 'remarkinfo',
  components: {
    remarkinfotitle,
    remarkpoint,
    remarkproduct
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'订单评价-微微定',
      sltms:false,
      rmkData:null,//订单数据
      rmksubmit:{},//提交数据
      disabledUpload:false,//显示隐藏匿名
      expReq:{
        point:0,
        txt:0
      },//验证计数
    }
  },
  props: {
    msg: String
  },
  methods:{
    sltm(){
      // 是否匿名
      this.sltms = !this.sltms
    },
    expupload(obj){
      // 导出图片数组
      // console.log(obj)
      this.rmksubmit = obj
    },
    rmkbtn(){
      if((!this.rmksubmit.point||this.rmksubmit.point<1)&&this.rmkData.ProductEvaluateList.length<1){
        this.expReq.point++
        return;
      }
      if(!this.rmksubmit.rmk||this.rmksubmit.rmk==''){
        this.expReq.txt++
        return;
      }
      this.disabledUpload = true;//禁用按钮
      // 提交发表
      var parms = {
        // CustomerId:this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'',
        token:this._token({method:'get'}),
        OrderName:this._spf(this.$route.query.orderName),
        ProductTypeId:this.rmkData.ProductTypeId,
        ProductId:this._spf(this.$route.query.ProductType),
        Content:this.rmksubmit.rmk,
        Score:this.rmksubmit.point*2,
        IsAnonymous:this.sltms?1:0,
      }
      for(let i in this.rmksubmit.uploaddata){
        parms['file'+i] = this.rmksubmit.uploaddata[i]
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/AddProductEvaluate',this.keys(parms))
          .then(res => {
            if(res.data.Success){
              this.disabledUpload = false;//解禁按钮
              this._router({then:()=>{
                this.$router.push({path: "/remarkresult.html"})
              },exp:true,catch:()=>{
                this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              }})
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    localReset(){
      this._axios({then:()=>{
        // 请求评论详情数据
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainProductEvaluate?OrderName='+this._spf(this.$route.query.orderName)+'&ProductId='+this._spf(this.$route.query.ProductType),this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // console.log(res.data.Data)
              this.rmkData = res.data.Data

            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.remarkinfo {
  .remarkinfotitle {
    width: 1200px;margin:0 auto;margin-bottom:25px;
  }
  .remarkpoint {
    width: 1150px;margin:0 auto;height: 50px;margin-bottom:10px;
  }
  .remarkproduct {
    width: 1200px;margin:0 auto;margin-bottom:10px;
  }
  .uploadBox {
    width: 1200px;margin:0 auto;background: #fff;height: 100px;margin-bottom:20px;display: flex;justify-content: center;align-items: center;
    button {
      width: 220px;height: 48px;background: #f44e55;border-radius: 5px;color:#fff;font-size: 16px;margin-right: 30px;
      &:hover {
        opacity: 0.8;
      }
    }
    .box {
      font-size:14px;color:#333;cursor: pointer;
      span {
        display: inline-block;width: 12px;height: 12px;border:1px solid #cecece;line-height: 12px;vertical-align: -1px;
        svg {
          width: 12px;height: 12px;fill: #f44e55;visibility: hidden;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .boxslt {
      span {
        svg {
          visibility: visible;
        }
      }
    }
  }
}
</style>
